import { Amount, Currency } from '@resolut-tech/bcn-rpcs';
import { Instance, types } from 'mobx-state-tree';

export const AmountModel = types.model({
    number: types.number,
    currency: types.string
});

export const getAmountModel = (amount: Amount): Instance<typeof AmountModel> => {
    return AmountModel.create({
        number: amount.amount / 10 ** 4,
        currency: amount.currency.currencyCode
    });
};

export const getAmountType = (amount: number, currency: string): Amount => {
    return new Amount(amount * 10 ** 4, new Currency(currency));
};
