import { Grid } from '@mui/material';
import { DropdownInputField, DropdownItem, SearchField } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableFilterContainer } from '../../common/components/TableFilterContainer';
import { getUserStatusOptions } from '../../common/utils/UIUtils';
import { AgentFilterOptions } from '../store/AgentSearchStore';
import { getAgentInitialFilter, getAgentSearchByOptions } from '../utils/UIUtils';
import { Button } from '../../../common/components/Button';

interface AgentSearchFilterProps {
    filter: Instance<typeof AgentFilterOptions>;
    setFilter: (newFilter: Instance<typeof AgentFilterOptions>) => void;
    minimumSearchTextLength: number;
    countryDropdownItems: DropdownItem[];
}

export const AgentSearchFilter = ({
    filter,
    setFilter,
    minimumSearchTextLength,
    countryDropdownItems
}: AgentSearchFilterProps): React.ReactElement => {
    const { t } = useTranslation();
    const initialFilter = getAgentInitialFilter(t);
    // This is used to check if the current filter combination is same as the initial filter combination.
    const isInitialFilter = JSON.stringify(filter) === JSON.stringify(initialFilter);
    const searchByOptions = getAgentSearchByOptions(t);
    const [searchValue, setSearchValue] = useState(filter.searchText);
    const [searchBy, setSearchBy] = useState(filter.searchBy);
    const [agentStatus, setAgentStatus] = useState(filter.selectedAgentStatus);
    const [country, setCountry] = useState(filter.selectedCountry);
    const [isSearchDisabled, setIsSearchDisabled] = useState(true);

    useEffect(() => {
        setSearchValue(filter.searchText);
        setSearchBy(filter.searchBy);
        setAgentStatus(filter.selectedAgentStatus);
        setCountry(filter.selectedCountry);
    }, [filter]);

    const onSearchChange = (inputValue: string): void => {
        setIsSearchDisabled(false);
        setSearchValue(inputValue);
    };

    const onSearchClear = (): void => {
        setSearchValue('');
        setIsSearchDisabled(false);
    };

    const onSearchByDropdownChange = (selected: DropdownItem): void => {
        setSearchBy(selected.value);
        setIsSearchDisabled(false);
        onSearchClear();
    };

    const onAgentStatusDropdownChange = (selected: DropdownItem): void => {
        setIsSearchDisabled(false);
        setAgentStatus(selected.value);
    };

    const onCountryDropdownChange = (selected: DropdownItem): void => {
        setIsSearchDisabled(false);
        setCountry(selected.value);
    };

    return (
        <TableFilterContainer>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <DropdownInputField
                        name="searchByDropdown"
                        value={searchBy}
                        onSelect={onSearchByDropdownChange}
                        label={t('common.searchBy')}
                        options={searchByOptions}
                    />
                </Grid>
                <Grid item xs={8}>
                    <SearchField
                        name="searchField"
                        value={searchValue}
                        isDisabled={false}
                        onTextChange={onSearchChange}
                        onClear={onSearchClear}
                        allowClearField={true}
                        placeholder={`${t('common.searchUsing')} ${
                            searchByOptions.find((item) => item.value === searchBy)!.name
                        }`}
                    />
                </Grid>
                <Grid item xs={4}>
                    <DropdownInputField
                        name="countryDropdown"
                        value={country}
                        onSelect={onCountryDropdownChange}
                        label={t('common.selectCountry')}
                        options={countryDropdownItems}
                    />
                </Grid>
                <Grid item xs={4}>
                    <DropdownInputField
                        name="agentStatusDropdown"
                        value={agentStatus}
                        onSelect={onAgentStatusDropdownChange}
                        label={t('agencyBanking.selectAgentStatus')}
                        options={getUserStatusOptions(t)}
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    gap="8px">
                    <Button
                        name="plain-color"
                        variant="plain-color"
                        color="error"
                        size="medium"
                        title={t('common.reset')}
                        isDisabled={isInitialFilter}
                        onClick={(): void => {
                            setIsSearchDisabled(true);
                            setFilter(initialFilter);
                        }}
                    />
                    <Button
                        name="filled"
                        variant="filled"
                        size="medium"
                        isDisabled={
                            (searchValue.length !== 0 &&
                                searchValue.length < minimumSearchTextLength) ||
                            isSearchDisabled
                        }
                        title={t('common.search')}
                        onClick={(): void => {
                            setIsSearchDisabled(true);
                            setFilter({
                                searchBy,
                                searchText: searchValue,
                                selectedAgentStatus: agentStatus,
                                selectedCountry: country
                            });
                        }}
                    />
                </Grid>
            </Grid>
        </TableFilterContainer>
    );
};
