import { LeoRPCError, LeoRPCResponse, LeoRPCResult } from '@surya-digital/leo-ts-runtime';

export const mockExecute = ({
    response,
    error
}: {
    response?: LeoRPCResponse;
    error?: LeoRPCError;
}): Promise<LeoRPCResult<LeoRPCResponse, LeoRPCError>> => {
    return new Promise((resolve) => {
        setTimeout(() => {
            if (response) {
                resolve(new LeoRPCResult.Response(response));
            }
            if (error) {
                resolve(new LeoRPCResult.Error(error));
            }
        }, 2000);
    });
};
