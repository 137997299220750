import React, { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { DropdownInputField, DropdownItem, SearchField } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import { useTranslation } from 'react-i18next';
import { TableFilterContainer } from '../../common/components/TableFilterContainer';
import {
    getAgentTransactionRequestsHistoryInitialFilter,
    getAgentTransactionRequestSearchByOptions
} from '../utils/UIUtils';
import { getIntermediateRequestStatusOptionsWithAny } from '../../common/utils/StatusUIUtils';
import { AgentTransactionRequestHistoryFilterOptions } from '../store/AgentTransactionRequestHistoryStore';
import { Button } from '../../../common/components/Button';

interface AgentTransactionRequestsHistoryFilterProps {
    filter: Instance<typeof AgentTransactionRequestHistoryFilterOptions>;
    setFilter: (newFilter: Instance<typeof AgentTransactionRequestHistoryFilterOptions>) => void;
    minimumSearchTextLength: number;
}

export const AgentTransactionRequestsHistoryFilter = ({
    filter,
    setFilter,
    minimumSearchTextLength
}: AgentTransactionRequestsHistoryFilterProps): React.ReactElement => {
    const { t } = useTranslation();
    const initialFilter = getAgentTransactionRequestsHistoryInitialFilter(t);
    // This is used to check if the current filter combination is same as the initial filter combination.
    const isInitialFilter = JSON.stringify(filter) === JSON.stringify(initialFilter);
    const searchByOptions = getAgentTransactionRequestSearchByOptions(t);
    const [searchText, setSearchText] = useState(filter.searchText);
    const [searchBy, setSearchBy] = useState(filter.searchBy);
    const [requestStatus, setRequestStatus] = useState(filter.requestStatus);
    const [disableSearch, setDisableSearch] = useState(true);

    useEffect(() => {
        setSearchText(filter.searchText);
        setSearchBy(filter.searchBy);
        setRequestStatus(filter.requestStatus);
    }, [filter]);

    const onSearchChange = (inputValue: string): void => {
        setDisableSearch(false);
        setSearchText(inputValue);
    };

    const onSearchClear = (): void => {
        setSearchText('');
        setDisableSearch(false);
    };

    const onSearchByDropdownChange = (selected: DropdownItem): void => {
        setSearchBy(selected.value);
        setDisableSearch(false);
        onSearchClear();
    };

    const onRequestStatusDropdownChange = (selected: DropdownItem): void => {
        setDisableSearch(false);
        setRequestStatus(selected.value);
    };

    return (
        <TableFilterContainer>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <DropdownInputField
                        name="searchByDropdown"
                        value={searchBy}
                        onSelect={onSearchByDropdownChange}
                        label={t('common.searchBy')}
                        options={searchByOptions}
                    />
                </Grid>
                <Grid item xs={8}>
                    <SearchField
                        name="searchField"
                        value={searchText}
                        isDisabled={false}
                        onTextChange={onSearchChange}
                        onClear={onSearchClear}
                        allowClearField={true}
                        placeholder={`${t('common.searchUsing')} ${
                            searchByOptions.find((item) => item.value === searchBy)!.name
                        }`}
                    />
                </Grid>
                <Grid item xs={4}>
                    <DropdownInputField
                        name="RequestStatusDropdown"
                        value={requestStatus}
                        onSelect={onRequestStatusDropdownChange}
                        label={t('common.requestStatus')}
                        options={getIntermediateRequestStatusOptionsWithAny()}
                    />
                </Grid>
                <Grid item xs={8} display="flex" justifyContent="flex-end" alignItems="center">
                    <Stack direction="row" spacing={1}>
                        <Button
                            name="plain-color"
                            variant="plain-color"
                            color="error"
                            size="medium"
                            title={t('common.reset')}
                            isDisabled={isInitialFilter}
                            onClick={(): void => {
                                setDisableSearch(true);
                                setFilter(initialFilter);
                            }}
                        />
                        <Button
                            name="filled"
                            variant="filled"
                            size="medium"
                            isDisabled={
                                (searchText.length !== 0 &&
                                    searchText.length < minimumSearchTextLength) ||
                                disableSearch
                            }
                            title={t('common.search')}
                            onClick={(): void => {
                                setDisableSearch(true);
                                setFilter(
                                    AgentTransactionRequestHistoryFilterOptions.create({
                                        searchBy,
                                        searchText,
                                        requestStatus
                                    })
                                );
                            }}
                        />
                    </Stack>
                </Grid>
            </Grid>
        </TableFilterContainer>
    );
};
