import { Comment, SubmitGenericFundsTransferRequestRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { flow, getParent, Instance, types } from 'mobx-state-tree';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { AmountModel, getAmountModel, getAmountType } from '../../common/models/AmountModel';
import { getAmountString } from '../../common/utils/UIUtils';
import { useSubmitGenericFundsTransferRequestRPCClient } from '../rpcs/RPC';
import { RequestFundTransferStore } from './RequestFundTransferStore';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum SubmitGenericFundsTransferRequestErrors {
    InvalidSenderAccount = 'INVALID_SENDER_ACCOUNT',
    SenderAccountInactive = 'SENDER_ACCOUNT_INACTIVE',
    SenderProfileDisabled = 'SENDER_PROFILE_DISABLED',
    InsufficientBalance = 'INSUFFICIENT_BALANCE',
    ReceivingAccountWouldCrossLimit = 'RECEIVING_ACCOUNT_WOULD_CROSS_LIMIT',
    CurrencyMismatch = 'CURRENCY_MISMATCH',
    AmountTooLess = 'AMOUNT_TOO_LESS',
    AmountTooLarge = 'AMOUNT_TOO_LARGE',
    InvalidLinkedTransactionId = 'INVALID_LINKED_TRANSACTION_ID',
    CannotTransferFundsToSameAccount = 'CANNOT_TRANSFER_FUNDS_TO_SAME_ACCOUNT',
    SystemToSystemTransactionNotAllowed = 'SYSTEM_TO_SYSTEM_TRANSACTION_NOT_ALLOWED',
    FeeCannotBeChargedFromSystemAccount = 'FEE_CANNOT_BE_CHARGED_FROM_SYSTEM_ACCOUNT',
    InvalidRecipientAccount = 'INVALID_RECIPIENT_ACCOUNT',
    RecipientAccountInactive = 'RECIPIENT_ACCOUNT_INACTIVE',
    RecipientProfileDisabled = 'RECIPIENT_PROFILE_DISABLED',
    RecipientProfileArchived = 'RECIPIENT_PROFILE_ARCHIVED',
    SenderProfileArchived = 'SENDER_PROFILE_ARCHIVED'
}

export const SubmitGenericFundsTransferRequestStore = types
    .model({
        senderAccountId: types.maybeNull(types.string),
        receiverAccountId: types.maybeNull(types.string),
        amount: types.maybeNull(AmountModel),
        fee: types.maybeNull(AmountModel),
        linkTransactionId: types.maybeNull(types.string),
        comment: types.maybeNull(types.string),
        errorLimitAmount: types.maybeNull(types.string),
        error: types.maybeNull(
            types.union(
                types.enumeration<SubmitGenericFundsTransferRequestErrors>(
                    'SubmitGenericFundsTransferErrors',
                    Object.values(SubmitGenericFundsTransferRequestErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        removeError(): void {
            store.error = null;
        },
        setComment(comment: string): void {
            store.comment = comment;
        },
        setUpStore(): void {
            const loggerStore = getLoggerStore(store);
            const requestGenericFundsTransferStore =
                getParent<typeof RequestFundTransferStore>(store).requestGenericFundsTransferStore;
            store.senderAccountId = requestGenericFundsTransferStore.senderAccountId;
            store.receiverAccountId = requestGenericFundsTransferStore.receiverAccountId;
            if (requestGenericFundsTransferStore.amount) {
                store.amount = AmountModel.create({
                    number: requestGenericFundsTransferStore.amount.number,
                    currency: requestGenericFundsTransferStore.amount.currency
                });
            } else {
                loggerStore.debug(
                    'Amount is not present in RequestGenericFundsTransferStore while setting up SubmitGenericFundsTransferStore'
                );
            }
            store.fee =
                requestGenericFundsTransferStore.fee &&
                AmountModel.create({
                    number: requestGenericFundsTransferStore.fee.number,
                    currency: requestGenericFundsTransferStore.fee.currency
                });
            store.linkTransactionId = requestGenericFundsTransferStore.linkTransactionId;
        },
        submitGenericFundsTransfer: flow(function* () {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            try {
                const senderAccountUUID = new LeoUUID(store.senderAccountId);
                try {
                    const receiverAccountUUID = new LeoUUID(store.receiverAccountId);
                    if (store.amount) {
                        const request = new SubmitGenericFundsTransferRequestRPC.Request(
                            senderAccountUUID,
                            receiverAccountUUID,
                            getAmountType(store.amount.number, store.amount.currency),
                            store.fee && getAmountType(store.fee.number, store.fee.currency),
                            store.linkTransactionId !== null
                                ? new LeoUUID(store.linkTransactionId)
                                : null,
                            // submitGenericFundsTransfer function is called only after comment is set. Hence store.comment cannot be null here.
                            new Comment(store.comment!)
                        );
                        const apiClient = getAPIClient(store);
                        const result: LeoRPCResult<
                            SubmitGenericFundsTransferRequestRPC.Response,
                            SubmitGenericFundsTransferRequestRPC.Errors.Errors
                        > = yield useSubmitGenericFundsTransferRequestRPCClient(apiClient).execute(
                            request
                        );
                        if (result instanceof LeoRPCResult.Response) {
                            return;
                        } else if (result instanceof LeoRPCResult.Error) {
                            const { error } = result;
                            switch (error.code) {
                                case SubmitGenericFundsTransferRequestErrors.InvalidSenderAccount:
                                    store.error =
                                        SubmitGenericFundsTransferRequestErrors.InvalidSenderAccount;
                                    break;
                                case SubmitGenericFundsTransferRequestErrors.SenderAccountInactive:
                                    store.error =
                                        SubmitGenericFundsTransferRequestErrors.SenderAccountInactive;
                                    break;
                                case SubmitGenericFundsTransferRequestErrors.SenderProfileDisabled:
                                    store.error =
                                        SubmitGenericFundsTransferRequestErrors.SenderProfileDisabled;
                                    break;
                                case SubmitGenericFundsTransferRequestErrors.InsufficientBalance:
                                    store.error =
                                        SubmitGenericFundsTransferRequestErrors.InsufficientBalance;
                                    break;
                                case SubmitGenericFundsTransferRequestErrors.ReceivingAccountWouldCrossLimit:
                                    store.error =
                                        SubmitGenericFundsTransferRequestErrors.ReceivingAccountWouldCrossLimit;
                                    break;
                                case SubmitGenericFundsTransferRequestErrors.AmountTooLess:
                                    if (
                                        error instanceof
                                        SubmitGenericFundsTransferRequestRPC.Errors.AmountTooLess
                                    ) {
                                        store.errorLimitAmount = getAmountString(
                                            getAmountModel(error.minimumAmount)
                                        );
                                    }

                                    store.error =
                                        SubmitGenericFundsTransferRequestErrors.AmountTooLess;
                                    break;
                                case SubmitGenericFundsTransferRequestErrors.AmountTooLarge:
                                    if (
                                        error instanceof
                                        SubmitGenericFundsTransferRequestRPC.Errors.AmountTooLarge
                                    ) {
                                        store.errorLimitAmount = getAmountString(
                                            getAmountModel(error.maximumAmount)
                                        );
                                    }

                                    store.error =
                                        SubmitGenericFundsTransferRequestErrors.AmountTooLarge;
                                    break;
                                case SubmitGenericFundsTransferRequestErrors.InvalidLinkedTransactionId:
                                    store.error =
                                        SubmitGenericFundsTransferRequestErrors.InvalidLinkedTransactionId;
                                    break;
                                case SubmitGenericFundsTransferRequestErrors.CurrencyMismatch:
                                    store.error =
                                        SubmitGenericFundsTransferRequestErrors.CurrencyMismatch;
                                    break;
                                case SubmitGenericFundsTransferRequestErrors.CannotTransferFundsToSameAccount:
                                    store.error =
                                        SubmitGenericFundsTransferRequestErrors.CannotTransferFundsToSameAccount;
                                    break;
                                case SubmitGenericFundsTransferRequestErrors.SystemToSystemTransactionNotAllowed:
                                    store.error =
                                        SubmitGenericFundsTransferRequestErrors.SystemToSystemTransactionNotAllowed;
                                    break;
                                case SubmitGenericFundsTransferRequestErrors.FeeCannotBeChargedFromSystemAccount:
                                    store.error =
                                        SubmitGenericFundsTransferRequestErrors.FeeCannotBeChargedFromSystemAccount;
                                    break;
                                case SubmitGenericFundsTransferRequestErrors.InvalidRecipientAccount:
                                    store.error =
                                        SubmitGenericFundsTransferRequestErrors.InvalidRecipientAccount;
                                    break;
                                case SubmitGenericFundsTransferRequestErrors.RecipientAccountInactive:
                                    store.error =
                                        SubmitGenericFundsTransferRequestErrors.RecipientAccountInactive;
                                    break;
                                case SubmitGenericFundsTransferRequestErrors.RecipientProfileDisabled:
                                    store.error =
                                        SubmitGenericFundsTransferRequestErrors.RecipientProfileDisabled;
                                    break;
                                case SubmitGenericFundsTransferRequestErrors.RecipientProfileArchived:
                                    store.error =
                                        SubmitGenericFundsTransferRequestErrors.RecipientProfileArchived;
                                    break;
                                case SubmitGenericFundsTransferRequestErrors.SenderProfileArchived:
                                    store.error =
                                        SubmitGenericFundsTransferRequestErrors.SenderProfileArchived;
                                    break;
                                default:
                                    loggerStore.error(
                                        `Unhandled error: ${error} occurred in SubmitGenericFundsTransferRequestRPC`
                                    );
                                    store.error = NetworkingError.InternalError;
                            }
                        } else {
                            loggerStore.error(
                                `Unknown error occurred in SubmitGenericFundsTransferRequestRPC with result: ${result}`
                            );
                            store.error = NetworkingError.InternalError;
                        }
                    } else {
                        loggerStore.debug(
                            'Amount is not present in SubmitGenericFundsTransferRequestRPC'
                        );
                        store.error = NetworkingError.InternalError;
                    }
                } catch (error) {
                    store.error = SubmitGenericFundsTransferRequestErrors.InvalidRecipientAccount;
                }
            } catch (error) {
                store.error = SubmitGenericFundsTransferRequestErrors.InvalidSenderAccount;
            }
        })
    }));

export const createSubmitGenericFundsTransferRequestStore = (): Instance<
    typeof SubmitGenericFundsTransferRequestStore
> => {
    return SubmitGenericFundsTransferRequestStore.create();
};
