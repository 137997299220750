import { Stack } from '@mui/material';
import { LoadingIndicator, Section } from '@surya-digital/leo-reactjs-ui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { SuccessDialog } from '../../common/components/dialog/SuccessDialog';
import { getAmountType } from '../../common/models/AmountModel';
import {
    useRequestFundTransferStore,
    useRequestGenericFundsTransferStore,
    useSubmitGenericFundsTransferRequestStore,
    useValidateAccountDetailsStore
} from '../store/hooks';
import { RequestGenericFundsTransferErrors } from '../store/RequestGenericFundsTransferStore';
import { SubmitGenericFundsTransferRequestErrors } from '../store/SubmitGenericFundsTransferStore';
import { AccountType } from '../store/ValidateAccountDetailsStore';
import { AmountDetails } from './AmountDetails';
import { FromAndToAccountDetailsSection } from './FromAndToAccountDetailsSection';
import { RequestTransferButtons } from './RequestTransferButtons';
import { TransferDetailsDialog } from './TransferDetailsDialog';

interface TransferDetailsComponentProps {
    onBack: () => void;
}
export const TransferDetailsComponent = ({
    onBack
}: TransferDetailsComponentProps): React.ReactElement => {
    const { t } = useTranslation();
    const store = useValidateAccountDetailsStore();
    const requestGenericFundsTransferStore = useRequestGenericFundsTransferStore();
    const submitGenericFundsTransferRequestStore = useSubmitGenericFundsTransferRequestStore();
    // store.senderAccountDetails is never null at this step.
    const senderCurrency = store.senderAccountDetails!.currency;
    const [amount, setAmount] = useState<string | null>(null);
    const [fee, setFee] = useState<string | null>(null);
    const [linkTransactionId, setLinkTransactionId] = useState<string | null>(null);
    const [isFeeCharged, setIsFeeCharged] = useState(false);
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [amountErrorText, setAmountErrorText] = useState<string | null>(null);
    const [feeErrorText, setFeeErrorText] = useState<string | null>(null);
    const [showTransactionIdError, setShowTransactionIdError] = useState(false);
    const [isRequestSubmitting, setIsRequestSubmitting] = useState(false);
    const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const requestFundTransferStore = useRequestFundTransferStore();

    const onRequestSubmitted = async (): Promise<void> => {
        setIsLoading(true);
        setIsRequestSubmitting(false);
        submitGenericFundsTransferRequestStore.setUpStore();
        await submitGenericFundsTransferRequestStore.submitGenericFundsTransfer();
        setIsLoading(false);
        if (submitGenericFundsTransferRequestStore.error) {
            switch (submitGenericFundsTransferRequestStore.error) {
                case SubmitGenericFundsTransferRequestErrors.InvalidSenderAccount:
                    setErrorMessage(t('common.invalidSenderAccountId'));
                    setIsErrorDialogOpen(true);
                    break;
                case SubmitGenericFundsTransferRequestErrors.InvalidRecipientAccount:
                    setErrorMessage(t('common.invalidReceiverAccountId'));
                    setIsErrorDialogOpen(true);
                    break;
                case SubmitGenericFundsTransferRequestErrors.CurrencyMismatch:
                    setErrorMessage(t('common.currencyMismatch'));
                    setIsErrorDialogOpen(true);
                    break;
                case SubmitGenericFundsTransferRequestErrors.AmountTooLarge:
                    setErrorMessage(
                        t('genericFundsTransfer.maxTransferableAmount', {
                            amount: submitGenericFundsTransferRequestStore.errorLimitAmount!
                        })
                    );
                    setIsErrorDialogOpen(true);
                    break;
                case SubmitGenericFundsTransferRequestErrors.AmountTooLess:
                    setErrorMessage(
                        t('genericFundsTransfer.minTransferableAmount', {
                            amount: submitGenericFundsTransferRequestStore.errorLimitAmount!
                        })
                    );
                    setIsErrorDialogOpen(true);
                    break;
                case SubmitGenericFundsTransferRequestErrors.InsufficientBalance:
                    setErrorMessage(t('common.insufficientBalance'));
                    setIsErrorDialogOpen(true);
                    break;
                case SubmitGenericFundsTransferRequestErrors.ReceivingAccountWouldCrossLimit:
                    setErrorMessage(t('common.receivingAccountWillCrossLimit'));
                    setIsErrorDialogOpen(true);
                    break;
                case SubmitGenericFundsTransferRequestErrors.InvalidLinkedTransactionId:
                    setErrorMessage(t('boUser.invalidLinkedTransactionId'));
                    setIsErrorDialogOpen(true);
                    break;
                case SubmitGenericFundsTransferRequestErrors.SenderAccountInactive:
                    setErrorMessage(t('common.senderAccountInactive'));
                    setIsErrorDialogOpen(true);
                    break;
                case SubmitGenericFundsTransferRequestErrors.RecipientAccountInactive:
                    setErrorMessage(t('common.receiverAccountInactive'));
                    setIsErrorDialogOpen(true);
                    break;
                case SubmitGenericFundsTransferRequestErrors.SenderProfileDisabled:
                    setErrorMessage(t('common.senderProfileDisabled'));
                    setIsErrorDialogOpen(true);
                    break;
                case SubmitGenericFundsTransferRequestErrors.RecipientProfileDisabled:
                    setErrorMessage(t('common.receiverProfileDisabled'));
                    setIsErrorDialogOpen(true);
                    break;
                case SubmitGenericFundsTransferRequestErrors.SystemToSystemTransactionNotAllowed:
                    setErrorMessage(t('genericFundsTransfer.transferBetweenSystemAccountsError'));
                    setIsErrorDialogOpen(true);
                    break;
                case SubmitGenericFundsTransferRequestErrors.CannotTransferFundsToSameAccount:
                    setErrorMessage(t('genericFundsTransfer.cannotTransferFundsToSameAccount'));
                    setIsErrorDialogOpen(true);
                    break;
                case SubmitGenericFundsTransferRequestErrors.FeeCannotBeChargedFromSystemAccount:
                    setErrorMessage(t('common.feeCannotBeChargedFromSystemAccount'));
                    setIsErrorDialogOpen(true);
                    break;
                case SubmitGenericFundsTransferRequestErrors.RecipientProfileArchived:
                    setErrorMessage(t('common.walletIdBelongsToArchiveUser'));
                    setIsErrorDialogOpen(true);
                    break;
                case SubmitGenericFundsTransferRequestErrors.SenderProfileArchived:
                    setErrorMessage(t('common.senderProfileArchived'));
                    setIsErrorDialogOpen(true);
                    break;
                case NetworkingError.InternalError:
                    setErrorMessage(t('common.somethingWentWrongProcessingRequest'));
                    setIsErrorDialogOpen(true);
                    break;
                default:
                    setErrorMessage(t('common.somethingWentWrongProcessingRequest'));
                    setIsErrorDialogOpen(true);
            }
        } else {
            setIsSuccessDialogOpen(true);
        }
    };

    useEffect(() => {
        if (!isErrorDialogOpen) {
            // This is done to ensure that once the error dialog is removed, the error is removed from the store as well.
            requestGenericFundsTransferStore.removeError();
            submitGenericFundsTransferRequestStore.removeError();
        }
    }, [isErrorDialogOpen]);

    useEffect(() => {
        const isDetailsValid =
            amount &&
            amount.trim().length !== 0 &&
            !amountErrorText &&
            !showTransactionIdError &&
            !feeErrorText;
        setIsButtonDisabled(!isDetailsValid);
    }, [amount, amountErrorText, showTransactionIdError, feeErrorText]);

    const onRequestTransfer = async (): Promise<void> => {
        setAmountErrorText(null);
        setFeeErrorText(null);
        setShowTransactionIdError(false);
        // we are handling the invalid amount error here as we saw the getAmountType function was not handled in store, hence we decided not to make more changes to stores which can leads to create bugs
        let isAmountErrorOcurred = false;
        let isFeeErrorOcurred = false;
        let fees = null;
        let _amount = null;
        try {
            fees =
                fee && fee.trim().length !== 0 ? getAmountType(Number(fee), senderCurrency) : null;
        } catch (error) {
            setFeeErrorText(t('common.invalidAmount'));
            isFeeErrorOcurred = true;
        }
        try {
            _amount = getAmountType(Number(amount!), senderCurrency);
        } catch (error) {
            setAmountErrorText(t('common.invalidAmount'));
            isAmountErrorOcurred = true;
        }
        if (!isFeeErrorOcurred && !isAmountErrorOcurred && _amount) {
            setIsLoading(true);
            requestGenericFundsTransferStore.setUpStore();
            await requestGenericFundsTransferStore.requestGenericFundsTransfer(
                _amount,
                linkTransactionId ?? null,
                fees
            );
            setIsLoading(false);
            if (requestGenericFundsTransferStore.error) {
                switch (requestGenericFundsTransferStore.error) {
                    case RequestGenericFundsTransferErrors.CurrencyMismatch:
                        setErrorMessage(t('common.currencyMismatch'));
                        setIsErrorDialogOpen(true);
                        break;
                    case RequestGenericFundsTransferErrors.AmountTooLarge:
                        if (isFeeCharged && fee) {
                            setAmountErrorText(
                                t('genericFundsTransfer.maxTransferableAmount', {
                                    amount: requestGenericFundsTransferStore.errorLimitAmount!
                                })
                            );
                            setFeeErrorText(
                                t('genericFundsTransfer.maxTransferableAmount', {
                                    amount: requestGenericFundsTransferStore.errorLimitAmount!
                                })
                            );
                        } else {
                            setAmountErrorText(
                                t('genericFundsTransfer.amountShouldBeLessThan', {
                                    amount: requestGenericFundsTransferStore.errorLimitAmount!
                                })
                            );
                        }
                        break;
                    case RequestGenericFundsTransferErrors.AmountTooLess:
                        if (isFeeCharged && fee) {
                            setAmountErrorText(
                                t('genericFundsTransfer.minTransferableAmount', {
                                    amount: requestGenericFundsTransferStore.errorLimitAmount!
                                })
                            );
                            setFeeErrorText(
                                t('genericFundsTransfer.minTransferableAmount', {
                                    amount: requestGenericFundsTransferStore.errorLimitAmount!
                                })
                            );
                        } else {
                            setAmountErrorText(
                                t('genericFundsTransfer.amountShouldBeMoreThan', {
                                    amount: requestGenericFundsTransferStore.errorLimitAmount!
                                })
                            );
                        }
                        break;
                    case RequestGenericFundsTransferErrors.InsufficientBalance:
                        setErrorMessage(t('common.insufficientBalance'));
                        setIsErrorDialogOpen(true);
                        break;
                    case RequestGenericFundsTransferErrors.ReceivingAccountWouldCrossLimit:
                        setErrorMessage(t('common.receivingAccountWillCrossLimit'));
                        setIsErrorDialogOpen(true);
                        break;
                    case RequestGenericFundsTransferErrors.InvalidLinkedTransactionId:
                        setShowTransactionIdError(true);
                        break;
                    case RequestGenericFundsTransferErrors.UnableToPerformExchange:
                    case NetworkingError.InternalError:
                        setIsErrorDialogOpen(true);
                        break;
                    case RequestGenericFundsTransferErrors.FeeCannotBeChargedFromSystemAccount:
                        setFeeErrorText(t('common.feeCannotBeChargedFromSystemAccount'));
                        break;
                }
            } else {
                setIsDetailsDialogOpen(true);
            }
        } else {
            // We should not make a networking call / open the refund dialog box if fee or amount validation resulted in an error
        }
    };

    useEffect(() => {
        if (isRequestSubmitting) {
            onRequestSubmitted();
        }
    }, [isRequestSubmitting]);

    useEffect(() => {
        if (!isFeeCharged) {
            setFee(null);
            setFeeErrorText(null);
            if (fee) {
                setAmountErrorText(null);
            }
        }
    }, [isFeeCharged]);

    return (
        <Stack spacing="32px">
            <LoadingIndicator isLoading={isLoading} />
            <ErrorDialog
                title={errorMessage && t('common.requestFundsTransfer')}
                errorMessage={errorMessage}
                isErrorDialogOpen={isErrorDialogOpen}
                onClose={(): void => {
                    if (submitGenericFundsTransferRequestStore.error) {
                        submitGenericFundsTransferRequestStore.removeError();
                    } else {
                        requestGenericFundsTransferStore.removeError();
                    }
                    requestFundTransferStore.resetStore();
                    setIsErrorDialogOpen(false);
                    setErrorMessage(null);
                    onBack();
                }}
            />
            <SuccessDialog
                title={t('common.requestFundsTransfer')}
                successMessage={t('genericFundsTransfer.requestSubmittedSuccessfully')}
                isDialogOpen={isSuccessDialogOpen}
                onCancel={(): void => {
                    // The user is navigated to step1 of GFT when SuccessDialog is closed.
                    requestFundTransferStore.resetStore();
                    requestGenericFundsTransferStore.resetStore();
                    setIsSuccessDialogOpen(false);
                    onBack();
                }}
            />
            {requestGenericFundsTransferStore.amount &&
                requestGenericFundsTransferStore.amountDebited &&
                requestGenericFundsTransferStore.amountCredited && (
                    <TransferDetailsDialog
                        isDialogOpen={isDetailsDialogOpen}
                        onClose={(): void => {
                            setIsDetailsDialogOpen(false);
                        }}
                        amount={requestGenericFundsTransferStore.amount}
                        fee={requestGenericFundsTransferStore.fee}
                        amountDebited={requestGenericFundsTransferStore.amountDebited}
                        amountCredited={requestGenericFundsTransferStore.amountCredited}
                        onSubmitRequest={(comment: string): void => {
                            setIsRequestSubmitting(true);
                            submitGenericFundsTransferRequestStore.setComment(comment);
                            setIsDetailsDialogOpen(false);
                        }}
                    />
                )}
            <Section
                title={t('genericFundsTransfer.enterAmount')}
                styleOverrides={{ width: '720px' }}
                allowViewChange={false}
                content={[
                    <Stack spacing="16px" key="TransferDetailsSection" sx={{ padding: '16px' }}>
                        <FromAndToAccountDetailsSection />
                        <AmountDetails
                            isFeeDisable={
                                requestFundTransferStore.senderAccountType === AccountType.System
                            }
                            amount={amount ?? ''}
                            fee={fee ?? ''}
                            transactionId={linkTransactionId ?? ''}
                            senderCurrency={senderCurrency}
                            amountErrorText={amountErrorText}
                            feeErrorText={feeErrorText}
                            onAmountChange={(value): void => {
                                if (
                                    requestGenericFundsTransferStore.error ===
                                        RequestGenericFundsTransferErrors.AmountTooLarge ||
                                    requestGenericFundsTransferStore.error ===
                                        RequestGenericFundsTransferErrors.AmountTooLess
                                ) {
                                    setAmountErrorText(null);
                                    setFeeErrorText(null);
                                }
                                setAmount(value);
                                setAmountErrorText(null);
                                requestGenericFundsTransferStore.removeError();
                            }}
                            onFeeChange={(value): void => {
                                if (
                                    fee &&
                                    (requestGenericFundsTransferStore.error ===
                                        RequestGenericFundsTransferErrors.AmountTooLarge ||
                                        requestGenericFundsTransferStore.error ===
                                            RequestGenericFundsTransferErrors.AmountTooLess)
                                ) {
                                    setAmountErrorText(null);
                                    setFeeErrorText(null);
                                }
                                setFee(value);
                                setFeeErrorText(null);
                                requestGenericFundsTransferStore.removeError();
                            }}
                            onTransactionIdChange={(value): void => {
                                setLinkTransactionId(value);
                                setShowTransactionIdError(false);
                            }}
                            showTransactionIdError={showTransactionIdError}
                            isFeeCharged={isFeeCharged}
                            setIsFeeCharged={(): void => {
                                setIsFeeCharged(!isFeeCharged);
                            }}
                        />
                    </Stack>
                ]}></Section>
            <RequestTransferButtons
                onPrimaryButtonClicked={onRequestTransfer}
                onSecondaryButtonClicked={(): void => {
                    store.resetStore();
                    requestGenericFundsTransferStore.resetStore();
                    onBack();
                }}
                isPrimaryButtonDisabled={isButtonDisabled}
            />
        </Stack>
    );
};
